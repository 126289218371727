<template>
  <el-container>
    <div class="bg" v-loading="loading">
      <div class="banner" :style="{ 'background-image': `url(${require('../../assets/images/common/top_banner_' + data.color + '.png')})` }">
        <h1>{{ data.warningType }}通知信息</h1>
        <p><span>预警通知于{{ data.pushTime }}自动生成</span></p>
      </div>
      <div class="wrapper">
        <div class="pad-lr-30">
          <h2>您好，以下为您的方案<span class="red">预警通知</span>:</h2>
          <div class="yjfa">
            <h3>方案 - {{ data.planName }}</h3>
            <p>{{ data.warningTypeDtail }}、{{ data.pushWeek }}</p>
            <p>推送条件：{{ data.pushGrade }}、{{ data.pushEmotion }}、{{ data.pushMedia }}</p>
            <p>数据范围：{{ data.startTime }} — {{ data.endTime }}</p>
            <p>
              相关数据：共{{ data.total }}条，包含
              <var v-for="(media, index) in mediaKeys" :key="index">
                {{ media }} {{ data.mediaCount[media] }}条{{ index + 1 < mediaKeys.length ? '、' : '' }}
              </var>
            </p>
          </div>
          <div class="yjlb" v-for="(media, index) in msgKeys" :key="index">
            <h4>{{ media }}</h4>
            <ul>
              <li v-for="(item, sub_index) in data.msgs[media]" :key="sub_index">
                <span class="ico-num">{{ sub_index + 1 }}</span>
                <div class="yj_cont">
                  <div class="ov-h w-100">
                    <span class="ico zy" v-if="item.grade == '重要'">重要</span>
                    <span class="ico yb" v-else>一般</span>
                    <h5 class="cur-p" @click="toSource(item.url)" v-html="item.title"></h5>
                    <br />
                    <p v-html="item.description"></p>
                    <var>{{ item.published }}</var>
                  </div>
                  <p class="ov-h w-100">{{ item.msg_info }}</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </el-container>
</template>
<script>
  import { message } from "@/api/caution";
  export default {
    name: "caution-list",
    data() {
      return {
        username: "",
        loading: false,
        data: {
          emotionCount: {},
          endTime: "",
          interval: [],
          mediaCount: {},
          msgs: {},
          planName: "",
          pushEmotion: "",
          pushGrade: "",
          pushMedia: "",
          pushTime: "",
          pushWeek: "",
          startTime: "",
          total: 0,
          warningType: "",
          warningTypeDtail: "",
          color: 'blue'
        },
        msgKeys: [],
        mediaKeys: []
      }
    },
    mounted() {
      this.loadData()
    },
    methods: {
      loadData() {
        if (!this.$route.query.hasOwnProperty("messageId")) {
          this.$router.push({path: "/404"})
          return false
        }
        this.loading = true
        message({messageId: this.$route.query.messageId}).then(res => {
          if (res.data.state) {
            let data = res.data.data
            data.result.color = data.result.color[0]
            this.data = data.result
            this.username = data.name
            this.loading = false
            // 按照指定媒体类型排序
            this.handleData(this.data)
          } else {
            this.$message.error(res.data.error)
            var _this = this
            setTimeout(function () {
              _this.$router.push({path: "/404"})
            }, 1500)
          }
        }).catch(() => {
          this.$message.error("获取预警明细失败，服务错误")
        })
      },
      handleData(data) {
        let sortMedia = ["网媒", "微博", "微信", "论坛", "博客", "报刊"]
        let msgs = JSON.parse(JSON.stringify(data.msgs))
        let medias = JSON.parse(JSON.stringify(data.mediaCount))
        this.msgKeys = []
        this.mediaKeys = []
        let _this = this
        sortMedia.forEach((item) => {
          if (msgs.hasOwnProperty(item)) {
            _this.msgKeys.push(item)
            delete msgs[item]
          }
          if (medias.hasOwnProperty(item)) {
            _this.mediaKeys.push(item)
            delete medias[item]
          }
        })
        for (let key in msgs) {
          _this.msgKeys.unshift(key)
        }
        for (let media in medias) {
          _this.mediaKeys.unshift(media)
        }
      },
      toSource(url) {
        window.open(url, "_blank")
      }
    }
  }
</script>
<style scoped>
  .bg{background: #fff;padding-bottom:30px;box-shadow: 15px 0 15px -15px rgba(194,194,194,0.4), -15px 0 15px -15px rgba(194,194,194,0.4);width:1380px;margin: 0 auto;}
  .banner{text-align: center;line-height: 60px;height: 200px; background-repeat:no-repeat; background-size:100% 100%;-moz-background-size:100% 100%;color: #fff;margin: 0 auto;width: 1380px;position: relative;}
  .banner .img{position: absolute;left:100px;top:50px;z-index: 10;height: 60px;}
  .banner h1{font-size: 30px;line-height:80px;padding-top:30px;}
  .banner span{padding: 0 30px;font-size: 22px;color:rgba(255,255,255,.79);}
  .wrapper{width:1100px;margin: 0 auto;background: #F8FAFF;padding: 0 30px 30px;}
  h2{color: #121212;font-size: 16px;line-height: 50px;border-bottom: 2px solid rgba(156,156,155,.5);}
  .red{color:red;}
  .yjfa h3{font-size: 14px;line-height: 30px;color: #000;padding-top: 10px;}
  .yjfa p{font-size: 14px;color: #121212;}
  .yjlb h4{background: #2B67B3;border-radius:4px 4px 0px 0px;line-height: 25px;padding-left: 20px;color:#fff;font-size: 14px;margin-top: 20px;}
  .yjlb li{line-height:30px;background: #fff;font-size: 14px;overflow: hidden;}
  .yjlb .ico-num{width: 30px;text-align: center;float: left;font-weight:600;color:#000;margin-top:10px;}
  .yjlb .yj_cont{border-bottom: 2px solid rgba(156,156,155,.5);float: left;width: 990px;padding: 10px 20px 10px 0;}
  .yjlb li:last-child .yj_cont{border: none;padding-bottom: 10px;}
  .yjlb .ico{width:42px;height:20px;line-height: 20px;float: left;border-radius:2px;text-align: center;margin:5px 10px 0 0;display: inline;}
  .yjlb .zy{color:#FD3533;border: 1px solid #FD3533;}
  .yjlb .yb{color:rgba(156,156,155,.7);border: 1px solid rgba(156,156,155,.7);}
  .yjlb h5{color:#000;line-height:30px;overflow: hidden;white-space: nowrap;text-overflow:ellipsis;float: left;width:790px;}
  .yjlb var{float: right;font-size: 12px;}
</style>